import React, { Component, useEffect, useState } from "react";
import { Opportunities } from "./Opportunities"
import { getTranslationByKey } from "../global";
import { InternalBackButton } from "./InternalBackButton";

import ReactPlayer from 'react-player'
import ReactHlsPlayer from 'react-hls-player';
import { OpportunitiesService } from "../Services/Services";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { OpportunitiesFaq } from "./OpportunitiesFaq";
import { BackButton } from "./BackButton";

export function OpportunitiesInfoPage(props) {
    const [view, setView] = React.useState('infopage')
    const oS = new OpportunitiesService()
    const [srcVideo, setSrcVideo] = useState("")
    const [guide, setguide] = useState(props.user.modules.opportunities.user_guide_img)
    const [guidePopup, setGuidePopup] = useState(false)
    const [selectedGuidePage, setselectedGuidePage] = useState(0)

    useEffect(() => {
        if (props.routingObj && props.routingObj.path) {
            setView('detailView')
        }
        oS.getInfoPageVideo(props.user.modules.opportunities.home_video).then((data) => {
            setSrcVideo(data)
        })
    }, [])


    /* guide */

    const openGuidePopup = () => {
        setGuidePopup(!guidePopup)
        setselectedGuidePage(0)
    }

    const changeGuidePage = (type) => {
        if (type === 'prev')
            setselectedGuidePage(selectedGuidePage - 1)
        else {
            setselectedGuidePage(selectedGuidePage + 1)
        }
    }

    let customModalStyles = {

        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            width: 'fit-content',
            height: '85vh',
            marginRight: '-50%',
            padding: '15px',
            transform: 'translate(-50%, -50%)',
            'z-index': 99,
            transition: "opacity 20ms ease-in -out",
            "background-color": "#" + guide[0].background_color + " !important"
        },

    };

    return (
        <div>
            {view === 'faq' ?
                (window.innerWidth > 1000 ?
                    <InternalBackButton name={'back_button_component_dx pointer flex'} key='selected_book' backButtonFunction={() => setView('infopage')} index={99} />
                    :
                    <BackButton backButtonFunction={() => setView('infopage')} index={210} />)
                : ''}
            {view === 'infopage' ?
                <div className="opportuinities_info">
                    <div className="opportunities_info_img"></div>
                    {window.innerWidth > 1000 ?
                        <div className="flex" Style="width: 95%; margin-bottom: 20px;">
                            <div className="opportunities_info_button opportunities_info_button_web" onClick={() => setView('faq')}>{getTranslationByKey('OPPORTUNITIES.BUTTON.FAQ')}</div>
                            <div className="opportunities_info_button opportunities_info_button_web" onClick={() => setView('opportunities')}>{getTranslationByKey('OPPORTUNITIES.BUTTON.GOTOOPPORTUNITIES')}</div>
                            <div className="opportunities_info_button opportunities_info_button_web" onClick={() => setGuidePopup(true)}>{getTranslationByKey('OPPORTUNITIES.BUTTON.USERGUIDE')}</div>
                        </div>

                        : ''
                    }
                    <div className="opportunities_infopage_mobile">
                        <div className="opportunities_info_text" >
                            <ReactPlayer
                                url={srcVideo.url}
                                controls={true}
                                playing={true}
                                muted={true}
                                width="100%"
                                height="auto"
                                className="opportunities_info_video"
                                playsinline
                                // Disable download button
                                config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                            />
                        </div>

                        {window.innerWidth > 1000 ? '' :
                            <React.Fragment>

                                <div className="opportunities_info_button" onClick={() => setView('opportunities')}>{getTranslationByKey('OPPORTUNITIES.BUTTON.GOTOOPPORTUNITIES')}</div>
                                <div className={window.innerWidth > 1000 ? "extra_info_button book pointer" : "opportunities_info_button"} onClick={() => setGuidePopup(true)}>{getTranslationByKey('OPPORTUNITIES.BUTTON.USERGUIDE')}</div>
                                <div className="opportunities_info_button" onClick={() => setView('faq')}>{getTranslationByKey('OPPORTUNITIES.BUTTON.FAQ')}</div>
                            </React.Fragment>
                        }
                    </div>
                </div> :
                view === 'faq' ?
                    <div className="opportuinities_info">
                        <div className="opportunities_faq_img"></div>
                        <div className="opportunities_faq_cont">
                            <OpportunitiesFaq faq_page_id={props.user.modules.opportunities.faq_page_id} />
                        </div>
                    </div> :
                    <Opportunities user={props.user} back={setView} routingObj={view==='detailView'? props.routingObj : ''}/>}
            <Dialog
                open={guidePopup}
                onAfterOpen={null}
                onClose={() => openGuidePopup(false)}
                style={customModalStyles}
                className="tutorial_dialog"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth="sm"
                sx={{ //You can copy the code below in your theme
                    background: 'transparent',
                    '& .MuiPaper-root': {
                        background: "#" + guide[selectedGuidePage].background_color + " !important"
                    },
                    '& .MuiBackdrop-root': {
                        backgroundColor: 'transparent' // Try to remove this to see the result
                    }
                }}
            >
                <DialogTitle>
                    <div className="close" >
                        <svg onClick={() => openGuidePopup()} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill={"#" + guide[selectedGuidePage].close_button_color} class="bi bi-x" viewBox="0 0 16 16">
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                    </div>
                </DialogTitle>
                <DialogContent>
                    {guide ?
                        <div className="modal_body">
                            <div className="desk_guide_container">
                                <div className=" item-center flex" Style={"justify-content: center;    display: flex;    align-items: center;"}>
                                    {selectedGuidePage > 0 ?
                                        <div>
                                            <svg onClick={() => changeGuidePage('prev')} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill={"#" + guide[selectedGuidePage].close_button_color} class="bi bi-chevron-left pointer" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                            </svg>
                                        </div>
                                        : <div Style={"width: 30px;"}></div>}
                                    <div className="desk_guide_page" Style={"background-image: url('" + guide[selectedGuidePage].url + "')"}></div>
                                    {selectedGuidePage < guide.length - 1 ?
                                        <div>

                                            <svg onClick={() => changeGuidePage('next')} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill={"#" + guide[selectedGuidePage].close_button_color} class="bi bi-chevron-right pointer" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                            </svg>
                                        </div>
                                        : <div Style={"width: 30px;"}></div>}
                                </div>
                            </div>
                        </div> : ''}
                </DialogContent>
            </Dialog>
        </div>
    )
}